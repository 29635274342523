import { useSlots as _useSlots, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "text-center text-[18px] font-bold" }
const _hoisted_3 = { class: "flex flex-wrap gap-x-[4.9%] gap-y-[12px] mx-[12px] mt-[12px]" }
const _hoisted_4 = {
  key: 0,
  class: "w-[30%] h-[100px]"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]

import { getIntegrateUrl } from '@/views/Pixi/components/utils'
import { ref, reactive } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageCard',
  props: {
  list: {
    type: Array,
    default() {
      return []
    }
  },
  title: String
},
  emits: ['click'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const { default: defaultSlot } = _useSlots()

const onClick = (item: any) => {
  emits('click', item)
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(__props.title), 1),
    _createElementVNode("div", _hoisted_3, [
      defaultSlot
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default")
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          onClick: () => onClick(item, index),
          class: "flex items-center justify-center w-[30%] h-[100px] bg-[#eee] cursor-pointer hover:opacity-[.9]"
        }, [
          _createElementVNode("img", {
            src: _unref(getIntegrateUrl)(item.src, `tplv-z8ialgywaa-w-size:300`),
            alt: "",
            class: "max-w-full max-h-full"
          }, null, 8, _hoisted_6)
        ], 8, _hoisted_5))
      }), 128))
    ])
  ]))
}
}

})