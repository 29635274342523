import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap gap-[10px] mx-[14px]" }

import { ref, reactive, onMounted } from 'vue'
import Card from '@/components/SideCard/index.vue'
import { BgData, MenuType, SkuFormat } from '../types'
import { getBackgroundApi, getCategoryListApi, getDesignApi, getProductSkuApi, getUserResrouceApi } from '@/api/common'
import { RelationProduct } from '@/views/Pixi/types'
import { useRoute } from 'vue-router'
import { Modal } from 'ant-design-vue'

// const [messageApi, contextHolder] = message.useMessage();

export default /*@__PURE__*/_defineComponent({
  __name: 'SideLeft',
  props: {
  data: {
    type: Object
  },
},
  emits: [
  'openDesignArea',
  'openPicture',
  'openText',
  'onSticker',
  'onBackground',
  'onDesign',
  'addText',
  'openSku'
],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const route = useRoute()

const CURRENCY_MAP = new Map([
  ['CNY', '￥'],
  ['HKD', 'HK$'],
])

const navList = [
  { title: '展示方式', iconfont: 'icon-yishu', type: 'designArea', },
  { title: '照片', iconfont: 'icon-tupian_huaban', type: 'picture', },
  { title: '文字', iconfont: 'icon-a-8ziti', type: 'text', },
  { title: '貼畫', iconfont: 'icon-wenjianjia', type: 'sticker', },
  { title: '背景', iconfont: 'icon-beijing', type: 'bg', },
  { title: '設計模板', iconfont: 'icon-xianxing-_caidan1', type: 'design', },
  { title: '款式', iconfont: 'icon-kuanshiguanli', type: 'sku' },
]

const getDesignAreaList = () => {
  return props.data?.designImage.map((t: string) => ({ src: t })) || []
}

const getCategoryAllList = async () => {
  try {
    const { data } = await getCategoryListApi()
    return data
  } catch(err) {

  }
}

const getPictureAllList = async () => {
  try {
    const { data } = await getUserResrouceApi()
    return data
  } catch(err) {

  }
}

const getBackground = async () => {
  try {
    const { data } = await getBackgroundApi({
      limit: 30,
      page: 1,
    })
    let color: BgData[] = []
    let image: BgData[] = []
    data.list.forEach((t: any) => {
      t.type === 'image' ? image.push(t) : color.push(t)
    })
    return {
      color,
      image
    }
  } catch(err) {
    console.log(err)
  }
}

const getDesign = async () => {
  try {
    const { data } = await getDesignApi({
      limit: 30,
      page: 1,
      publish_status: 'published'
    })
    return data.list
  } catch(err) {
    console.log(err)
  }
}

const relationProduct = ref<RelationProduct | undefined>()
const getProductSku = async (id: string) => {
  const { app_id, currency } = route.query
  // if (typeof currency !== 'string') {
  //   return
  // }
  try {
    const { data } = await getProductSkuApi(id, typeof app_id === 'string' ? app_id : '', currency || 'CNY')
    relationProduct.value = data.product
    let skuList: SkuFormat[] = data.variant.map((t: any) => {
      const specLen = Object.keys(t).filter(k => /specname\d+/.test(k)).length
      let sku_attrs = {}
      for (let index = 0; index < specLen; index++) {
        let i = index + 1
        let specname = t[`specname${i}`]
        let option = t[`option${i}`]
        if (specname && option) {
          sku_attrs = {
            ...sku_attrs,
            [specname]: option
          }
        }
      }
      return {
        id: t.v_id,
        price: Number(t.converted_price).toFixed(2),
        currency: CURRENCY_MAP.get(t.target_currency),
        stock: t.availableStockNum,
        relation_id: t.relation_id,
        sku_attrs,
      }
    })
    return skuList
    
  } catch(err) {
    console.log(err)
  }
}

onMounted(async () => {
  let { pid } = route.query
  const skus = typeof pid === 'string' ? (await getProductSku(pid)) : null
  if (!skus) {
    return Modal.error({
      title: '産品不存在'
    })
  }
  emits('openSku', skus, relationProduct.value)
})

const onClick = async (type: MenuType) => {
  switch(type) {
    case 'designArea':
      return emits('openDesignArea', getDesignAreaList())
    case 'picture':
      const picture = await getPictureAllList()
      return emits('openPicture', picture)
    case 'text':
      emits('addText')
      return emits('openText')
    case 'sticker':
      const sticker = await getCategoryAllList()
      return emits('onSticker', sticker)
    case 'bg':
      const bg = await getBackground()
      return emits('onBackground', bg)
    case 'design':
      const design = await getDesign()
      return emits('onDesign', design)
    case 'sku':
      let { pid } = route.query
      const skus = typeof pid === 'string' ? (await getProductSku(pid)) : null
      if (!skus) {
        return Modal.error({
          title: '産品不存在'
        })
      }
      return emits('openSku', skus, relationProduct.value)
  }
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(navList, (item, index) => {
      return _createVNode(Card, {
        key: index,
        title: item.title,
        iconfont: item.iconfont,
        onClick: ($event: any) => (onClick(item.type))
      }, null, 8, ["title", "iconfont", "onClick"])
    }), 64))
  ]))
}
}

})