import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-y-[20px] mt-[12px] opera" }
const _hoisted_2 = { class: "flex gap-[12px] items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "flex gap-[12px] items-center" }
const _hoisted_6 = { class: "flex gap-[12px] items-center" }
const _hoisted_7 = {
  key: 6,
  class: "flex items-center justify-center h-[300px]"
}

import { ref, reactive, watch } from 'vue'
import {
  Form,
  FormItem ,
  Input,
  Button,
  Slider,
  Switch,
  Empty,
  Select,
  SelectOption
} from 'ant-design-vue';
import Upload from '@/components/Upload/index.vue'
import ImageCard from './ImageCard.vue'
import SbujectCard from './SbujectCard.vue'
import BgCard from './BgCard.vue'
import { BgData, FormImageChangeType, OperaType, UpdateImageOperaData } from '../types'
import { IAddBgType, IConfigFontStyle, IParams } from '@/types';
import { getIntegrateUrl } from '@/views/Pixi/components/utils';
import { getUserResrouceApi } from '@/api/common';

interface IFontItem {
  url?: string;
  imgUrl?: string;
  checked?: boolean
  name: string;
}

interface FormState {
  text: string;

  username: string;
  password: string;
  remember: boolean;
}

type ImageCardListType = {
  src: string
}[]

export default /*@__PURE__*/_defineComponent({
  __name: 'Opera',
  props: {
  formData: {
    type: Object,
    default() {
      return {}
    }
  },
},
  emits: [
  'addText',
  'addImage',
  'addBackground',
  'loadDesign',
  'setDesginArea',
  'updateTextSprite',
  'setMirror',
  'setSpriteCartoon',
  'setSpriteCutout',
  'fillColorSprite',
  'setSpriteTexture',
  'fillImageSpite',
],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

const font = reactive<{
  list: IFontItem[]
}>({
  list: [
    {
      name: '默認字體',
    },
    {
      url: 'https://web.plugcustom.com.cn/plug-fonts/SourceHanSansCN-Bold.otf',
      name: '思源黑體',
      imgUrl: 'https://web.plugcustom.com.cn/plug-fonts/%E6%80%9D%E6%BA%90%E9%BB%91%E4%BD%93.png',
      checked: false,
    },
    {
      url: 'https://web.plugcustom.com.cn/plug-fonts/SoukouMincho.ttf',
      name: '裝甲明朝體',
      imgUrl: 'https://web.plugcustom.com.cn/plug-fonts/%E8%A3%85%E7%94%B2%E6%98%8E%E6%9C%9D%E4%BD%93.png',
      checked: false,
    },
    {
      url: 'https://web.plugcustom.com.cn/plug-fonts/%E4%BB%93%E8%80%B3%E8%88%92%E5%9C%86%E4%BD%93W03.ttf',
      name: '倉耳舒圓體',
      imgUrl: 'https://web.plugcustom.com.cn/plug-fonts/%E4%BB%93%E8%80%B3%E8%88%92%E5%9C%86%E4%BD%93.png',
      checked: false,
    },
    {
      url: 'https://web.plugcustom.com.cn/plug-fonts/%E6%B1%9F%E5%9F%8E%E6%AD%A3%E4%B9%89%E4%BD%93%20400W.ttf',
      name: '江城正義體',
      imgUrl: 'https://web.plugcustom.com.cn/plug-fonts/%E6%B1%9F%E5%9F%8E%E6%AD%A3%E4%B9%89%E4%BD%93.png',
      checked: false,
    },
  ]
})

const formState = reactive<any>({
  username: '',
  text: '',
  password: '',
  remember: true,
  fontFamily: '默認字體',
  fontWeight: undefined,
  stroke: '#ffffff',
  strokeThickness: 1,
  fill: undefined,
});
const onFinish = (values: any) => {
  console.log('Success:', values);
};

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};
const operaType = ref<OperaType>()
const operaTitle = ref<string>('')
const designAreaList = ref<ImageCardListType>([])
const pictureList = ref<any[]>([])
const stickerList = ref<any[]>([])
const designList = ref<any[]>([])
const bgColorList = ref<BgData[]>([])
const bgImageList = ref<BgData[]>([])

const onDesignAreaClick = ({ src }: any) => {
  console.log(src, '--src')
  emits('setDesginArea', src)
}
const onDesignClick = (data: IParams) => {
  console.log(data)
  emits('loadDesign', data, true)
}
const onPictureClick = (item: any) => {
  emits('addImage', item.src)
}
const onStickerClick = (urlValue: string) => {
  emits('addImage', urlValue)
}
const onBgClick = (type: IAddBgType, value: string) => {
  emits('addBackground', type, value)
}
const onImageChange = (url: string) => {
  formState.imageUrl = getIntegrateUrl(url, `tplv-z8ialgywaa-w-size:150`)
  emits('setSpriteTexture', url)
}

const onFillImageChange = (url?: string) => {
  if (!url) {
    emits('fillImageSpite')
    return
  }
  formState.imageFill = getIntegrateUrl(url, `tplv-z8ialgywaa-w-size:150`)
  emits('fillImageSpite', url)
}

const onFormImageChange = (type: FormImageChangeType, val: any) => {
  switch(type) {
    case 'mirrorX':
      emits('setMirror', 'x')
      break
    case 'mirrorY':
      emits('setMirror', 'y')
      break
    case 'cartoon':
      emits('setSpriteCartoon')
      break
    case 'cutout':
    case 'cutoutStuff':
      emits('setSpriteCutout', type === 'cutout' ? 'people' : 'stuff')
      break
  }
}

const downLoadFont = async (name: string, url: string) => {
  const font = new FontFace(name, `url(${url})`)
  try {
    const loadedFontFace = await font.load()
    document.fonts.add(loadedFontFace)
    console.log('字體加載完成')
  } catch(err) {
    console.log(err)
    console.log('字體加載失敗')
  }
}

let changeTime: null | number = null
const onFormDelayTextChange = (field: string, val: string) => {
  console.log(val)
  changeTime && clearTimeout(changeTime)
  changeTime = setTimeout(async () => {
    switch(field) {
      case 'fillColor':
        emits('fillColorSprite', val)
        break
      case 'fontFamily':
        let item = font.list.find(t => t.name === val)
        if (item) {
          await downLoadFont(item?.name, item?.url)
        }
        updateTextSprite()
        break
      default:
        updateTextSprite()
        break
    }
    changeTime && clearTimeout(changeTime)
    changeTime = null
  }, 500)
}
const updateTextSprite = () => {
  let { text, fontFamily, fontWeight, strokeThickness, stroke, fill } = formState
  emits('updateTextSprite', text, {
    fontFamily,
    fontWeight,
    strokeThickness,
    stroke,
    fill,
  })
}

const onPictureFail = () => {}
const onPictureChange = async () => {
  const { data: picture } = await getUserResrouceApi()
  pictureList.value = picture
}

watch(() => props.formData, (formData) => {
  formState.text = formData.text
}, { deep: true })

const handleReset = () => {
  if (formState.lrMirror) {
    onFormImageChange('mirrorX', 'x')
  }
  if (formState.tbMirror) {
    onFormImageChange('mirrorX', 'y')
  }
  if (formState.cartoon) {
    onFormImageChange('cartoon')
  }
  if (formState.cutout) {
    onFormImageChange('cutout')
  }
  if (formState.cutoutStuff) {
    onFormImageChange('cutoutStuff')
  }
  if (formState.imageFill) {
    onFillImageChange()
  }
  if (formState.fillColor) {
    emits('fillColorSprite')
  }

  formState.text = '默認文案'
  formState.fontWeight = 0
  formState.fill = '#101112'
  formState.strokeThickness = 0
  formState.stroke = '#ffffff'

  formState.lrMirror = undefined
  formState.tbMirror = undefined
  formState.cartoon = undefined
  formState.cutout = undefined
  formState.cutoutStuff = undefined
  formState.imageFill = undefined
  formState.fillColor = undefined

  updateTextSprite()
}

__expose({
  setDesignAreaList: (list: string[]) => {
    designAreaList.value = list.map(t => ({ src: t }))
  },
  openDesignArea: (list: ImageCardListType) => {
    operaType.value = 'designArea'
    operaTitle.value = '藝術闆'
    designAreaList.value = list
  },
  openPicture: (list: any[]) => {
    // console.log(list, '-list')
    operaType.value = 'picture'
    operaTitle.value = '照片'
    pictureList.value = list
  },
  openText: () => {
    operaType.value = 'text'
  },
  onSticker: (list: any[]) => {
    operaType.value = 'sticker'
    stickerList.value = list
  },
  onBackground: ({ color, image }: { color: BgData[], image: BgData[] }) => {
    operaType.value = 'bg'
    bgColorList.value = color
    bgImageList.value = image
  },
  onDesign: (list: any[]) => {
    operaType.value = 'design'
    operaTitle.value = '設計模闆'
    designList.value = list.map(t => ({
      ...t,
      src: t.effect_image,
    }))
  },
  updateText: (option: IConfigFontStyle & { text: string }) => {
    operaType.value = 'text'
    
    formState.text = option.text
    formState.fontWeight = option.fontWeight
    formState.fill = option.fill
    formState.strokeThickness = option.strokeThickness
    formState.stroke = option.stroke
  },
  reset: () => {
    formState.text = ''
    formState.fontWeight = undefined
    formState.fill = undefined
    formState.strokeThickness = undefined
    formState.stroke = undefined

    formState.lrMirror = undefined
    formState.tbMirror = undefined
    formState.cartoon = undefined
    formState.cutout = undefined
    formState.cutoutStuff = undefined
    formState.imageFill = undefined
    formState.fillColor = undefined

    operaType.value = undefined
  },
  openImage: () => {
    operaType.value = 'image'
  },
  updateImage: (option: UpdateImageOperaData) => {
    console.log(option, '--option')
    formState.imageUrl = option.imageUrl
    formState.lrMirror = option.lrMirror
    formState.tbMirror = option.tbMirror
    formState.cartoon = option.cartoon
    formState.cutout = option.cutoutType === 'people' && option.cutout
    formState.cutoutStuff = option.cutoutType === 'stuff' && option.cutout
    formState.imageFill = option.imageFill
    formState.fillColor = option.fillColor
  },
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
        operaType.value === 'designArea'
      )
      ? (_openBlock(), _createBlock(ImageCard, {
          key: 0,
          title: operaTitle.value,
          list: designAreaList.value,
          onClick: onDesignAreaClick
        }, null, 8, ["title", "list"]))
      : (
        operaType.value === 'design'
      )
        ? (_openBlock(), _createBlock(ImageCard, {
            key: 1,
            title: operaTitle.value,
            list: designList.value,
            onClick: onDesignClick
          }, null, 8, ["title", "list"]))
        : (
        operaType.value === 'picture'
      )
          ? (_openBlock(), _createBlock(ImageCard, {
              key: 2,
              title: operaTitle.value,
              list: pictureList.value,
              onClick: onPictureClick
            }, {
              default: _withCtx(() => [
                _createVNode(Upload, {
                  hasUpdate: true,
                  onChange: onPictureChange,
                  onFail: onPictureFail
                })
              ]),
              _: 1
            }, 8, ["title", "list"]))
          : (
        operaType.value === 'bg'
      )
            ? (_openBlock(), _createBlock(BgCard, {
                key: 3,
                title: operaTitle.value,
                colorList: bgColorList.value,
                imageList: bgImageList.value,
                onClick: onBgClick
              }, null, 8, ["title", "colorList", "imageList"]))
            : (
        operaType.value === 'sticker'
      )
              ? (_openBlock(), _createBlock(SbujectCard, {
                  key: 4,
                  title: operaTitle.value,
                  list: stickerList.value,
                  onClick: onStickerClick
                }, null, 8, ["title", "list"]))
              : (operaType.value === 'image' || operaType.value === 'text')
                ? (_openBlock(), _createBlock(_unref(Form), {
                    key: 5,
                    class: "mr-[20px]",
                    model: formState,
                    name: "basic",
                    "label-col": { span: 6 },
                    "wrapper-col": { span: 18 },
                    autocomplete: "off",
                    onFinish: onFinish,
                    onFinishFailed: onFinishFailed
                  }, {
                    default: _withCtx(() => [
                      (operaType.value === 'image')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createVNode(_unref(FormItem), {
                              label: "圖片",
                              name: "imageUrl"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(Upload, {
                                  value: formState.imageUrl,
                                  onChange: onImageChange
                                }, null, 8, ["value"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(FormItem), {
                              label: "左右鏡像",
                              name: "lrMirror"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Switch), {
                                  checked: formState.lrMirror,
                                  "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((formState.lrMirror) = $event)),
                                  onChange: _cache[1] || (_cache[1] = () => onFormImageChange('mirrorX', 'x')),
                                  "checked-children": "開",
                                  "un-checked-children": "關"
                                }, null, 8, ["checked"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(FormItem), {
                              label: "上下鏡像",
                              name: "tbMirror"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Switch), {
                                  checked: formState.tbMirror,
                                  "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((formState.tbMirror) = $event)),
                                  onChange: _cache[3] || (_cache[3] = () => onFormImageChange('mirrorY', 'y')),
                                  "checked-children": "開",
                                  "un-checked-children": "關"
                                }, null, 8, ["checked"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(FormItem), {
                              label: "漫畫風",
                              name: "cartoon"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Switch), {
                                  checked: formState.cartoon,
                                  "onUpdate:checked": _cache[4] || (_cache[4] = ($event: any) => ((formState.cartoon) = $event)),
                                  onChange: _cache[5] || (_cache[5] = () => onFormImageChange('cartoon')),
                                  "checked-children": "開",
                                  "un-checked-children": "關"
                                }, null, 8, ["checked"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(FormItem), {
                              label: "人像摳圖",
                              name: "cutout"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Switch), {
                                  checked: formState.cutout,
                                  "onUpdate:checked": _cache[6] || (_cache[6] = ($event: any) => ((formState.cutout) = $event)),
                                  onChange: _cache[7] || (_cache[7] = () => onFormImageChange('cutout')),
                                  "checked-children": "開",
                                  "un-checked-children": "關"
                                }, null, 8, ["checked"]),
                                _cache[30] || (_cache[30] = _createElementVNode("p", { class: "text-[12px] mt-[6px] text-[#999]" }, "僅人像使用", -1))
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(FormItem), {
                              label: "主體摳圖",
                              name: "cutoutStuff"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Switch), {
                                  checked: formState.cutoutStuff,
                                  "onUpdate:checked": _cache[8] || (_cache[8] = ($event: any) => ((formState.cutoutStuff) = $event)),
                                  onChange: _cache[9] || (_cache[9] = () => onFormImageChange('cutoutStuff')),
                                  "checked-children": "開",
                                  "un-checked-children": "關"
                                }, null, 8, ["checked"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(FormItem), {
                              label: "圖片填充",
                              name: "username"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(Upload, {
                                  value: formState.imageFill,
                                  onChange: onFillImageChange
                                }, null, 8, ["value"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(FormItem), {
                              label: "顔色填充",
                              name: "fillColor"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_2, [
                                  _createVNode(_unref(Input), {
                                    class: "flex-auto w-auto",
                                    value: formState.fillColor,
                                    "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((formState.fillColor) = $event)),
                                    onInput: _cache[11] || (_cache[11] = (e) => onFormDelayTextChange('fillColor', e.target.value)),
                                    placeholder: "#000000"
                                  }, null, 8, ["value"]),
                                  _withDirectives(_createElementVNode("input", {
                                    type: "color",
                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((formState.fillColor) = $event)),
                                    onInput: _cache[13] || (_cache[13] = (e) => onFormDelayTextChange('fillColor', e.target.value))
                                  }, null, 544), [
                                    [_vModelText, formState.fillColor]
                                  ])
                                ])
                              ]),
                              _: 1
                            })
                          ], 64))
                        : _createCommentVNode("", true),
                      (operaType.value === 'text')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createVNode(_unref(FormItem), {
                              label: "內容",
                              name: "text"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Input), {
                                  value: formState.text,
                                  "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((formState.text) = $event)),
                                  onInput: _cache[15] || (_cache[15] = (e) => onFormDelayTextChange('text', e.target.value)),
                                  placeholder: "文字內容"
                                }, null, 8, ["value"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(FormItem), {
                              label: "字體",
                              name: "password"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Select), {
                                  ref: "select",
                                  value: formState.fontFamily,
                                  "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((formState.fontFamily) = $event)),
                                  onChange: _cache[17] || (_cache[17] = (e) => onFormDelayTextChange('fontFamily', e)),
                                  style: {"overflow":"hidden"}
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(font.list, (item, index) => {
                                      return (_openBlock(), _createBlock(_unref(SelectOption), {
                                        key: index,
                                        value: item.name
                                      }, {
                                        default: _withCtx(() => [
                                          (item.url)
                                            ? (_openBlock(), _createElementBlock("img", {
                                                key: 0,
                                                style: {"max-width":"85%"},
                                                src: item.imgUrl,
                                                alt: ""
                                              }, null, 8, _hoisted_3))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.name), 1))
                                        ]),
                                        _: 2
                                      }, 1032, ["value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["value"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(FormItem), {
                              label: "粗細",
                              name: "fontWeight"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Slider), {
                                  value: formState.fontWeight,
                                  "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((formState.fontWeight) = $event)),
                                  onChange: _cache[19] || (_cache[19] = (e) => onFormDelayTextChange('fontWeight', e)),
                                  step: 100,
                                  min: 400,
                                  max: 700,
                                  disabled: _ctx.disabled
                                }, null, 8, ["value", "disabled"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(FormItem), {
                              label: "顔色",
                              name: "fill"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_5, [
                                  _createVNode(_unref(Input), {
                                    class: "flex-auto w-auto",
                                    value: formState.fill,
                                    "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((formState.fill) = $event)),
                                    onChange: _cache[21] || (_cache[21] = (e) => onFormDelayTextChange('fill', e.target.value)),
                                    placeholder: "#000000"
                                  }, null, 8, ["value"]),
                                  _withDirectives(_createElementVNode("input", {
                                    type: "color",
                                    "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((formState.fill) = $event)),
                                    onInput: _cache[23] || (_cache[23] = (e) => onFormDelayTextChange('fill', e.target.value))
                                  }, null, 544), [
                                    [_vModelText, formState.fill]
                                  ])
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(FormItem), {
                              label: "邊框大小",
                              name: "strokeThickness"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Slider), {
                                  value: formState.strokeThickness,
                                  "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((formState.strokeThickness) = $event)),
                                  onChange: _cache[25] || (_cache[25] = (e) => onFormDelayTextChange('strokeThickness', e)),
                                  disabled: _ctx.disabled,
                                  max: 10
                                }, null, 8, ["value", "disabled"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(FormItem), {
                              label: "邊框顔色",
                              name: "stroke"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_6, [
                                  _createVNode(_unref(Input), {
                                    class: "flex-auto w-auto",
                                    value: formState.stroke,
                                    "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((formState.stroke) = $event)),
                                    onChange: _cache[27] || (_cache[27] = (e) => onFormDelayTextChange('stroke', e.target.value)),
                                    placeholder: "#ffffff"
                                  }, null, 8, ["value"]),
                                  _withDirectives(_createElementVNode("input", {
                                    type: "color",
                                    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((formState.stroke) = $event)),
                                    onInput: _cache[29] || (_cache[29] = (e) => onFormDelayTextChange('stroke', e.target.value))
                                  }, null, 544), [
                                    [_vModelText, formState.stroke]
                                  ])
                                ])
                              ]),
                              _: 1
                            })
                          ], 64))
                        : _createCommentVNode("", true),
                      _createVNode(_unref(FormItem), { "wrapper-col": { offset: 6, span: 18 } }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Button), {
                            type: "primary",
                            onClick: handleReset
                          }, {
                            default: _withCtx(() => _cache[31] || (_cache[31] = [
                              _createTextVNode("重置")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_unref(Empty), { description: "請點擊菜單或者選中圖片" })
                  ]))
  ]))
}
}

})