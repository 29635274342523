import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, unref as _unref } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-y-[30px]" }
const _hoisted_2 = { class: "flex flex-wrap gap-x-[4%] gap-y-[12px] mx-[12px]" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex flex-wrap gap-x-[4%] gap-y-[12px] mx-[12px]" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]

import { IAddBgType } from '@/types'
import { getIntegrateUrl } from '@/views/Pixi/components/utils'
import { ref, reactive } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'BgCard',
  props: {
  colorList: {
    type: Array,
    default() {
      return []
    }
  },
  imageList: {
    type: Array,
    default() {
      return []
    }
  },
  title: String
},
  emits: ['click'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const onClick = (type: IAddBgType, val: string) => {
  emits('click', type, type === 'image' ? val : val.replace('#', ''))
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _cache[0] || (_cache[0] = _createElementVNode("p", { class: "text-center text-[18px] font-bold" }, "顔色", -1)),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.colorList, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            onClick: () => onClick('color', item.colorvalue),
            class: "flex items-center justify-center w-[22%] h-[50px] cursor-pointer hover:opacity-[.9]",
            style: _normalizeStyle({ backgroundColor: item.colorvalue })
          }, null, 12, _hoisted_3))
        }), 128))
      ])
    ]),
    _createElementVNode("div", null, [
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-center text-[18px] font-bold" }, "圖片", -1)),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.imageList, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            onClick: () => onClick('image', item.urlvalue),
            class: "flex items-center justify-center w-[22%] h-[90px] hover:opacity-[.9] cursor-pointer"
          }, [
            _createElementVNode("img", {
              src: _unref(getIntegrateUrl)(item.urlvalue, `tplv-z8ialgywaa-w-size:300`),
              alt: "",
              class: "max-w-full max-h-full"
            }, null, 8, _hoisted_6)
          ], 8, _hoisted_5))
        }), 128))
      ])
    ])
  ]))
}
}

})